import { swapClasses, valueInRange } from "./utils";

/**
 * Init KR input events.
 */
export function initKrInput() {
    const krRadios = document.querySelectorAll(
        ".kr-container input[type=radio]"
    );

    const krInputs = document.querySelectorAll(
        ".kr-container input[type=number]"
    );

    if (!krRadios?.length) {
        return;
    }

    const krCards = document.querySelectorAll(".kr-container .card-range");
    const krButtonDecorations = document.querySelectorAll(
        ".kr-container .button-decoration"
    );
    const krLabelTexts = document.querySelectorAll(".kr-container label > p");
    const currentChecked = [...krRadios].filter((radio) => radio.checked)[0]
        .value;

    krRadios.forEach((radio) => {
        handleClickKrToggle(
            currentChecked,
            krCards,
            krButtonDecorations,
            krLabelTexts,
            krRadios
        );

        radio.addEventListener("click", (event) => {
            const [[idPreffix]] = event.target.id.split("-");
            handleClickKrToggle(
                idPreffix,
                krCards,
                krButtonDecorations,
                krLabelTexts,
                krRadios
            );
        });
    });

    krInputs.forEach((input) => {
        input.addEventListener("blur", () => {
            onBlurKrInput(
                input,
                krCards,
                krButtonDecorations,
                krLabelTexts,
                krRadios
            );
        });
    });
}

/**
 * @param {id} string
 * @param {HTMLElement[]} krCards
 * @param {HTMLElement[]} krButtonDecorations
 * @param {HTMLElement[]} krLabelTexts
 * @param {HTMLInputElement[]} krRadios
 */
function handleClickKrToggle(
    id,
    krCards,
    krButtonDecorations,
    krLabelTexts,
    krRadios
) {
    const krInputs = document.querySelectorAll(
        ".kr-container input[type=number]"
    );
    krInputs.forEach((input) => {
        if (input.value) {
            onBlurKrInput(
                input,
                krCards,
                krButtonDecorations,
                krLabelTexts,
                krRadios
            );
        }
    });
    setKrRadiosChecked(id, krRadios);
    setKrElementsStyles(id, krCards, krButtonDecorations, krLabelTexts);
}

/**
 * Set styles for KR elements.
 * @param {string} id
 * @param {HTMLElement[]} krCards
 * @param {HTMLElement[]} decorations
 * @param {HTMLElement[]} labelTexts
 */
function setKrElementsStyles(id, krCards, decorations, labelTexts) {
    decorations.forEach((decoration) => {
        setKrToggleDecoration(id, decoration);
    });

    setKrCardsStyle(id, krCards);
    setLabelTextsStyle(id, labelTexts);
}

/**
 * Set KR toggle decoration according to id.
 * @param {string} id
 * @param {HTMLElement} decoration
 */
function setKrToggleDecoration(id, decoration) {
    if (id === "k") {
        swapClasses(decoration, "first-selected", "second-selected");
    } else {
        swapClasses(decoration, "second-selected", "first-selected");
    }
}

/**
 *
 * @param {string} idPreffix
 * @param {HTMLElement[]} krCards
 */
function setKrCardsStyle(idPreffix, krCards) {
    krCards.forEach((card) => {
        if (card.id[0] == idPreffix) {
            card.classList.add("show");
        } else {
            card.classList.remove("show");
        }
    });
}

/**
 *
 * @param {string} idPreffix
 * @param {HTMLElement[]} labelTexts
 */
function setLabelTextsStyle(idPreffix, labelTexts) {
    labelTexts.forEach((text) => {
        if (text.closest("label").getAttribute("for")[0] === idPreffix) {
            text.classList.add("toggle-active");
        } else {
            text.classList.remove("toggle-active");
        }
    });
}

/**
 * Switch between K/R when the entered value is within the opposite range.
 * @param {HTMLInputElement} input
 * @param {HTMLElement[]} cardsRange
 * @param {HTMLElement[]} krButtonDecorations
 * @param {HTMLElement[]} krLabelTexts
 * @param {HTMLInputElement[]} krRadios
 */
function onBlurKrInput(
    input,
    cardsRange,
    krButtonDecorations,
    krLabelTexts,
    krRadios
) {
    const [radioName] = input.id.split("-");
    const oppositeKr = getOppositeKR(radioName[0]);
    const oppositeRadioName = radioName.replace(radioName[0], oppositeKr);

    const sliderRangeKR = document.querySelector(`#${radioName}-range`);
    const oppositeSliderRangeKR = document.querySelector(
        `#${oppositeRadioName}-range`
    );
    const oppositeInputRangeKR = document.querySelector(
        `#${oppositeRadioName}-input`
    );

    const currentValue = Number(input.value);
    const min = sliderRangeKR.getAttribute("min");
    const max = sliderRangeKR.getAttribute("max");

    const oppositeMin = oppositeSliderRangeKR.getAttribute("min");
    const oppositeMax = oppositeSliderRangeKR.getAttribute("max");

    oppositeSliderRangeKR.value = valueInRange(
        getValKR(Number(currentValue)),
        oppositeMin,
        oppositeMax
    ).toFixed(2);
    oppositeInputRangeKR.value = valueInRange(
        getValKR(Number(currentValue)),
        oppositeMin,
        oppositeMax
    ).toFixed(2);

    if (currentValue >= oppositeMin && currentValue <= oppositeMax) {
        sliderRangeKR.value = valueInRange(
            getValKR(Number(currentValue)),
            min,
            max
        );
        input.value = valueInRange(getValKR(Number(currentValue)), min, max);

        oppositeSliderRangeKR.value = Number(currentValue).toFixed(2);
        oppositeInputRangeKR.value = Number(currentValue).toFixed(2);

        handleClickKrToggle(
            oppositeKr,
            cardsRange,
            krButtonDecorations,
            krLabelTexts,
            krRadios
        );
    }
}

/**
 * Get opposite KR value;
 * @param {string} value k or r
 * @returns string
 */
function getOppositeKR(value) {
    return value.toLowerCase() === "k" ? "r" : "k";
}

/**
 * Set KR radio checked attribute.
 * @param {string} id
 * @param {HTMLInputElement[]} krRadios
 */
function setKrRadiosChecked(id, krRadios) {
    krRadios.forEach((krRadio) => {
        if (krRadio.id[0] == id) {
            krRadio.checked = true;
        } else {
            krRadio.checked = false;
        }
    });
}

/**
 * Calculate and get the KR value according to the CONST_VALUE
 * @param {number} value
 * @returns number
 */
export function getValKR(value) {
    // https://www.sciencedirect.com/topics/nursing-and-health-professions/cornea-curvature
    // explanation for 337.5
    const hypotheticalRefractiveIndex = 337.5;
    return hypotheticalRefractiveIndex / value;
}
