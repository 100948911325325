import { FormValidator } from "../../form_validator";
import { ExtraError, TreatmentStatus } from "../constants";
import { FormValidatorExtension } from "../extension";
import { isBaseRowEmpty } from "./common/visit_repeater";

export class TreatmentRepeaterExtension extends FormValidatorExtension {
    constructor() {
        super();
        this.treatmentValidator = FormValidator.getInstance(
            "score-treatment-form"
        );
    }

    init(formValidator) {
        this.formValidator = formValidator;
        const radios = document.querySelectorAll(
            "input[name=has-used-treatment]"
        );

        radios.forEach((radio) => {
            radio.addEventListener("input", () => {
                if (radio.value === "no") {
                    this.treatmentValidator.cleanErrors();
                    this.formValidator.cleanErrors();
                }
            });
        });
    }

    beforeValidate(field) {
        if (!field.element.closest("#treatment-input-repeater")) {
            return;
        }
        const error = document.getElementById("stop-date-error");
        const status = this.treatmentValidator.getField("status");
        const stop = this.treatmentValidator.getField("stopDate");
        const hasUsedTreatment =
            this.formValidator.getField("hasUsedTreatment");

        if (
            this.treatmentValidator.validateForm(true) ||
            hasUsedTreatment?.effectiveValue === "no"
        ) {
            this.formValidator.removeExtraError(ExtraError.TREATMENT_DATA);
        }

        if (
            (status.effectiveValue &&
                status.effectiveValue === TreatmentStatus.ONGOING) ||
            (status.effectiveValue !== "ongoing" && stop.effectiveValue)
        ) {
            this.formValidator.removeExtraError(ExtraError.STOP_DATE_REQUIRED);
            error.classList.add("invisible");
        }
    }

    beforeSubmit() {
        const error = document.getElementById("stop-date-error");
        const status = this.treatmentValidator.getField("status");
        const stop = this.treatmentValidator.getField("stopDate");
        const hasUsedTreatment =
            this.formValidator.getField("hasUsedTreatment");
        const [isEmpty] = isBaseRowEmpty("treatment-input-repeater");

        if (isEmpty) {
            return;
        }

        if (
            hasUsedTreatment?.effectiveValue === "yes" &&
            !this.treatmentValidator.validateForm()
        ) {
            this.formValidator.addExtraError(ExtraError.TREATMENT_DATA);
        } else {
            this.formValidator.removeExtraError(ExtraError.TREATMENT_DATA);
        }

        if (
            status.effectiveValue &&
            status.effectiveValue !== "ongoing" &&
            !stop.effectiveValue
        ) {
            this.formValidator.addExtraError(ExtraError.STOP_DATE_REQUIRED);
            error.classList.remove("invisible");
        } else {
            this.formValidator.removeExtraError(ExtraError.STOP_DATE_REQUIRED);
            error.classList.add("invisible");
        }
    }
}
