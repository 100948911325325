import { like, valueInRange } from "./utils";

/**
 * Define option object.
 * @typedef {{slug: string, name: string}} AutoCompleteOption
 */

/**
 * Initialise autocomplete select functionality.
 */
export function initAutoCompleteSelect() {
    const autocompletes = document.querySelectorAll(".autocomplete-select");

    autocompletes.forEach((autoComplete) => {
        setupAutoComplete(autoComplete);
    });
}

/**
 * Holds indepedent autocomplete states and actions.
 * @param {HTMLElement} autoComplete
 */
function setupAutoComplete(autoComplete) {
    let currentFocus = -1;
    const input = autoComplete.querySelector("input");
    const list = autoComplete.querySelector("ul");
    const { jsonOptions } = autoComplete.dataset;
    const options = JSON.parse(jsonOptions);

    /**
     * Focus an option in the autocomplete list.
     */
    const focusOption = () => {
        if (!list.children?.length) {
            return;
        }
        unfocusOptions();
        [...list.children][currentFocus].classList.add("focused");
    };

    /**
     * Unfocus all options in the autocomplete list.
     */
    const unfocusOptions = () => {
        [...list.children].forEach((option) => {
            option.classList.remove("focused");
        });
    };

    /**
     *
     * @param {AutoCompleteOption} option
     * @returns {boolean}
     */
    const optionMatchesInput = (option) => {
        return like(option.name.toUpperCase(), `${input.value.toUpperCase()}`);
    };

    /**
     * Filter options according to the current input value.
     * @returns {AutoCompleteOption[]}
     */
    const filteredOptions = () =>
        options.filter((option) => optionMatchesInput(option));

    /**
     * Creates a li element for a given option.
     * @param {AutoCompleteOption} option
     * @returns {HTMLLIElement}
     */
    const createOptionItem = (option) => {
        const item = document.createElement("li");
        const itemInput = createItemInput(option);

        const onClickItem = () => {
            input.value = itemInput.value;
            unfocusOptions();
            closeAutoComplete();

            // Dispatch custom event so that the autocomplete input data
            // is emitted to other modules (e.g. form validator).
            input.dispatchEvent(
                new CustomEvent("input-autocomplete", { detail: input.value })
            );
        };

        buildOptionText(item, option);
        item.appendChild(itemInput);
        list.appendChild(item);
        item.addEventListener("click", onClickItem);

        return item;
    };

    /**
     * Build the text of an option item so that if a piece of the input value
     * matches the option name, it gets bolded.
     * @param {HTMLLIElement} item
     * @param {AutoCompleteOption} option
     */
    const buildOptionText = (item, option) => {
        let boldPart = "";
        let boldIndex = -1;

        if (optionMatchesInput(option)) {
            boldIndex = option.name
                .toUpperCase()
                .indexOf(input.value.toUpperCase());

            boldPart += option.name.slice(
                boldIndex,
                boldIndex + input.value.length
            );
        }

        const firstPart = option.name.slice(0, boldIndex >= 0 ? boldIndex : 0);

        const lastPart = option.name.slice(
            boldIndex + boldPart.length,
            option.name.length
        );

        item.innerHTML = `${firstPart}<strong>${boldPart}</strong>${lastPart}`;
    };

    /**
     * Creates a hidden input to store the option value so that it is submittable in a form.
     * @param {AutoCompleteOption} option
     * @returns {HTMLInputElement}
     */
    const createItemInput = (option) => {
        const itemInput = document.createElement("input");
        itemInput.setAttribute("type", "hidden");
        itemInput.value = option.name;
        return itemInput;
    };

    /**
     * Add every matched option to the autocomplete list.
     */
    const renderOptions = () => {
        const matchedOptions = filteredOptions();

        matchedOptions.forEach((option) => {
            createOptionItem(option);
        });

        autoComplete.appendChild(list);
    };

    /**
     * Destroys all option items in the autocomplete list.
     * @param {HTMLElement|undefined} target
     */
    const closeAutoComplete = (target = undefined) => {
        [...list.children].forEach((item) => {
            if (target != item && target != input) {
                item.parentNode.removeChild(item);
            }
        });
    };

    /**
     * Displays autocomplete list on the screen.
     * @param {HTMLElement|undefined} target
     */
    const openAutoComplete = () => {
        closeAutoComplete();
        unfocusOptions();
        renderOptions();
    };

    /**
     * Controls the arrows keys so that it highlights the focused option.
     * @param {number} index
     */
    const handleArrowKey = (index) => {
        const max = list.children.length - 1;
        currentFocus = valueInRange(currentFocus + index, 0, max);
        focusOption();
    };

    /**
     * Controls the enter key to select the focused option.
     * @param {number} index
     */
    const handleEnterKey = (event) => {
        event.preventDefault();

        if (list.children?.length) {
            [...list.children][currentFocus].click();
        }
    };

    /**
     * Arrow keys handlers for controlling the autocomplete list.
     * @param {KeyboardEvent} event
     */
    const onKeyDown = (event) => {
        switch (event.key) {
            case "ArrowDown":
                return handleArrowKey(1);
            case "ArrowUp":
                return handleArrowKey(-1);
            case "Enter":
                return handleEnterKey(event);
            default:
                return;
        }
    };

    input.addEventListener("input", openAutoComplete);
    input.addEventListener("focus", openAutoComplete);
    input.addEventListener("keydown", onKeyDown);
    document.addEventListener("click", (e) => closeAutoComplete(e.target));
}
