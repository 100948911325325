/**
 * Creates a stateful variable with getter and setter functions.
 * @param {any} initialValue
 * @returns {[() => any, (newValue: any) => void]}
 */
export function state(initialValue) {
    let state = initialValue;

    const setState = (newValue) => {
        state = newValue;
    };
    const getState = () => state;

    return [getState, setState];
}
