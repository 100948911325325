import { FormValidatorExtension } from "../extension";
import { upperCaseFirstLetter } from "../../../utils";

export class ConfirmFieldExtension extends FormValidatorExtension {
    getConfirmKey(field) {
        return `confirm${upperCaseFirstLetter(field.validatorKey)}`;
    }

    getConfirmField(confirmKey) {
        return [...this.formValidator.fields].find(
            (field) => field[1].validatorKey === confirmKey
        );
    }

    beforeValidate(field) {
        if (field.element.classList.contains("match-input")) {
            const confirmKey = this.getConfirmKey(field);
            const [, confirmField] = this.getConfirmField(confirmKey);

            const [selector, className] = this.formValidator.getErrorSelector(
                confirmField.element
            );

            const errorMessage =
                this.formValidator.getCustomErrorMessages()[confirmKey].match;

            if (field.effectiveValue !== confirmField.effectiveValue) {
                confirmField.updateErrors([errorMessage]);
                selector.classList.add(className);
            } else {
                confirmField.updateErrors([]);
                selector.classList.remove(className);
            }
        }
    }
}
