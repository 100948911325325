/**
 * Init the autotab library
 * @param {*} inputs
 * @param {Array} triggers
 */
export function initAutotab(inputs, triggers) {
    const MIN = 0;
    const MAX = inputs.length;
    let index;

    // Add event listeners to inputs
    for (let i = 0; i < inputs.length; i++) {
        inputs[i].addEventListener("focus", function () {
            index = i;
        });
        inputs[i].addEventListener("keydown", function (e) {
            tabKeydown(e);
        });
        inputs[i].addEventListener("keyup", function (e) {
            tabKeyup(e);
        });
    }

    function isValidKey(key, regex) {
        return (
            regex.test(key) ||
            key === "Backspace" ||
            key === "ArrowRight" ||
            key === "ArrowLeft"
        );
    }

    function goToNextInput(index, key) {
        return (
            inputs[index].value.length ==
                inputs[index].getAttribute("maxlength") &&
            index + 1 < MAX &&
            key !== "Backspace" &&
            key !== "ArrowRight" &&
            key !== "ArrowLeft"
        );
    }

    function goRight(index, key) {
        return (
            inputs[index].selectionStart == inputs[index].value.length &&
            index + 1 < MAX &&
            key === "ArrowRight"
        );
    }

    function goBack(index, key) {
        return (
            inputs[index].value.length == 0 &&
            index > MIN &&
            key === "Backspace"
        );
    }

    function goLeft(index, key) {
        return (
            inputs[index].selectionStart == 0 &&
            index > MIN &&
            key === "ArrowLeft"
        );
    }

    /**
     * Go to the next or previous entry according to the conditions
     * Trigger on keyup event
     * @param {*} event - event from keyup
     */
    function tabKeyup(event) {
        const key = event.key || event.code;
        const { pattern } = inputs[index];
        const regex = new RegExp("^" + pattern + "$", "g");

        if (isValidKey(key, regex)) {
            if (goToNextInput(index, key)) {
                inputs[index + 1].focus();
            } else if (goRight(index, key)) {
                inputs[index + 1].focus();
            } else if (goBack(index, key)) {
                inputs[index - 1].focus();
            } else if (goLeft(index, key)) {
                inputs[index - 1].focus();
            }
        } else {
            event.preventDefault();
        }
    }

    /**
     * Go to the next or previous entry according to the conditions
     * Trigger on keydown event
     * @param {*} event - event from keydown
     */
    function tabKeydown(event) {
        const key = event.key || event.code;
        const { pattern } = inputs[index];
        const regex = new RegExp("^" + pattern + "$", "g");

        if (
            !regex.test(key) &&
            key !== "Backspace" &&
            key !== "Enter" &&
            key !== "Tab" &&
            key !== "ArrowRight" &&
            key !== "ArrowLeft"
        ) {
            event.preventDefault();
            // Press one of the key contained in the triggers array
            // Go to the next entry if all conditions are satisfy
            if (isValidTrigger(key) && index + 1 < MAX) {
                inputs[index + 1].focus();
            }
        }
    }

    /**
     * Check if the key is contained in the triggers array
     * @param {string} key - char
     */
    function isValidTrigger(key) {
        for (const trigger of triggers) {
            if (trigger === key) {
                return true;
            }
        }
        return false;
    }
}
