import { z } from "zod";
import {
    optionalRangeSchema,
    rangeSchema,
    stringSchema,
    linkedSchema,
    dependentField,
    demographicPatientDataSchema,
    dependentFields,
    dateSchema,
} from "./common";
import { reduceEntries } from "../../../utils";

const sphereSchema = (error) => rangeSchema(-9, 9, error);
const cylinderSchema = (error) => optionalRangeSchema(-6, 6, error);
const axisSchema = (error) => optionalRangeSchema(0, 180, error);
const kSchema = (error) => optionalRangeSchema(35, 50, error);
const rSchema = (error) => optionalRangeSchema(6.5, 10, error);
const axialLengthSchema = (error) => optionalRangeSchema(20, 28, error);

const axialSchema = (errorObject) => {
    return z.object({
        ...linkedSchema(
            "measuredAxialLengthOD",
            axialLengthSchema,
            errorObject
        ),
        ...linkedSchema(
            "measuredAxialLengthOS",
            axialLengthSchema,
            errorObject
        ),
    });
};

const krSchema = (errorObject) => {
    return z.object({
        pickKR: stringSchema(errorObject?.pickKR),
        ...linkedSchema("k1OD", kSchema, errorObject),
        ...linkedSchema("k2OD", kSchema, errorObject),
        ...linkedSchema("r1OD", rSchema, errorObject),
        ...linkedSchema("r2OD", rSchema, errorObject),
        ...linkedSchema("k1OS", kSchema, errorObject),
        ...linkedSchema("k2OS", kSchema, errorObject),
        ...linkedSchema("r1OS", rSchema, errorObject),
        ...linkedSchema("r2OS", rSchema, errorObject),
    });
};

export const buildGuideSchema = (errorObject) => {
    return z
        .object({
            ...linkedSchema("sphereOD", sphereSchema, errorObject),
            ...linkedSchema("cylinderOD", cylinderSchema, errorObject),
            ...linkedSchema("axisOD", axisSchema, errorObject),
            ...linkedSchema("sphereOS", sphereSchema, errorObject),
            ...linkedSchema("cylinderOS", cylinderSchema, errorObject),
            ...linkedSchema("axisOS", axisSchema, errorObject),
        })
        .merge(demographicPatientDataSchema(errorObject))
        .merge(axialSchema(errorObject))
        .merge(krSchema(errorObject))
        .refine(
            ...dependentField(
                "linkCylinderOD",
                "linkAxisOD",
                errorObject?.linkCylinderOD?.dependent
            )
        )
        .refine(
            ...dependentField(
                "linkAxisOD",
                "linkCylinderOD",
                errorObject?.linkAxisOD?.dependent
            )
        )
        .refine(
            ...dependentField(
                "linkCylinderOS",
                "linkAxisOS",
                errorObject?.linkCylinderOS?.dependent
            )
        )
        .refine(
            ...dependentField(
                "linkAxisOS",
                "linkCylinderOS",
                errorObject?.linkAxisOS?.dependent
            )
        )
        .refine(
            ...dependentField(
                "measuredAxialLengthOD",
                "measuredAxialLengthOS",
                errorObject?.measuredAxialLengthOD?.dependent
            )
        )
        .refine(
            ...dependentField(
                "measuredAxialLengthOS",
                "measuredAxialLengthOD",
                errorObject?.measuredAxialLengthOS?.dependent
            )
        )
        .superRefine((data, ctx) => {
            const measuredData = reduceEntries(data, (key) =>
                key.startsWith("linkMeasured")
            );
            const estimatedData =
                data.pickKR === "k"
                    ? reduceEntries(data, (key) => key.startsWith("linkK"))
                    : reduceEntries(data, (key) => key.startsWith("linkR"));

            const measuredValues = Object.values(measuredData);
            const estimatedValues = Object.values(estimatedData);
            const isMeasuredFilled = measuredValues.some((data) => data);

            const isMeasuredInvalid =
                isMeasuredFilled && !measuredValues.every((data) => data);

            if (isMeasuredInvalid) {
                const [path] = Object.entries(measuredData).find(
                    (entry) => !entry[1]
                );

                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: errorObject?.[path]?.required,
                    path: [path],
                });

                return z.NEVER;
            }

            const estimatedMatches = Object.entries(estimatedData).every(
                ([id, value]) => {
                    const matchKey = id.includes("1")
                        ? id.slice().replace("1", "2")
                        : id.slice().replace("2", "1");

                    const matchData = estimatedData[matchKey];

                    return (value && matchData) || (!value && !matchData);
                }
            );

            if (!estimatedMatches && !isMeasuredFilled) {
                const [path] = Object.entries(estimatedData).find(
                    (entry) => !entry[1]
                );

                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: errorObject?.[path]?.required,
                    path: [path],
                });
            }

            const isEstimatedInvalid =
                measuredValues.every((data) => !data) &&
                estimatedValues.some((data) => data) &&
                !estimatedValues.every((data) => data);

            if (isEstimatedInvalid && !isMeasuredFilled) {
                const invalids = Object.entries(estimatedData).filter(
                    (entry) => !entry[1]
                );

                invalids.forEach((invalid) => {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: errorObject?.[invalid[0]]?.required,
                        path: [invalid[0]],
                    });
                });
            }

            return z.NEVER;
        });
};

export const buildScoreRowScheme = (errorObject) => {
    const baseSchema = z.object({
        visitDate: dateSchema(errorObject?.visitDate),
        cylinderRight: cylinderSchema(errorObject?.cylinderRight),
        axisRight: axisSchema(errorObject?.axisRight),
        cylinderLeft: cylinderSchema(errorObject?.cylinderLeft),
        axisLeft: axisSchema(errorObject?.axisLeft),
    });
    const withSphereSchema = z.object({
        sphereRight: sphereSchema(errorObject?.sphereRight),
        sphereLeft: sphereSchema(errorObject?.sphereLeft),
        axialLengthRight: rangeSchema(
            20,
            28,
            errorObject?.axialLengthRight
        ).optional(),
        axialLengthLeft: rangeSchema(
            20,
            28,
            errorObject?.axialLengthLeft
        ).optional(),
    });

    const withAxialSchema = z.object({
        axialLengthRight: rangeSchema(20, 28, errorObject?.axialLengthRight),
        axialLengthLeft: rangeSchema(20, 28, errorObject?.axialLengthLeft),
        sphereRight: sphereSchema(errorObject?.sphereRight).optional(),
        sphereLeft: sphereSchema(errorObject?.sphereLeft).optional(),
    });

    return z
        .union([withSphereSchema, withAxialSchema])
        .and(baseSchema)
        .refine(
            ...dependentField(
                "cylinderRight",
                "axisRight",
                errorObject?.cylinderRight?.dependent,
                errorObject?.axisRight?.dependent
            )
        )
        .refine(
            ...dependentFields(
                "cylinderLeft",
                "axisLeft",
                errorObject?.cylinderLeft?.dependent,
                errorObject?.axisLeft?.dependent
            )
        )
        .refine(
            ...dependentFields(
                "axialLengthLeft",
                "axialLengthRight",
                errorObject?.axialLengthLeft.dependent,
                errorObject?.axialLengthRight.dependent
            )
        );
};

export const buildScoreTreatmentScheme = (errorObject) => {
    return z.object({
        treatment: stringSchema(errorObject?.treatment, 999),
        startDate: dateSchema(errorObject?.startDate),
        status: stringSchema(errorObject?.status),
        eyes: stringSchema(errorObject?.eyes),
    });
};
