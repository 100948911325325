import { updateTheme } from "../api/theme_api";
import { setInputValue, swapClassesIf } from "./utils";

const LIGHT_CLASS = "light";
const DARK_CLASS = "dark";

/**
 * Initialise theme toogle.
 */
export function initThemeToggle() {
    const toggle = document.getElementById("async-theme-toggle");

    if (!toggle) {
        return;
    }

    const { token } = toggle.dataset;
    const input = toggle.querySelector("input");

    setThemeInputValue(input);

    toggle.addEventListener("click", () => {
        handleClickToggle(input, toggle, token);
    });
}

/**
 * Check if user is using dark theme.
 */
export function isDarkTheme() {
    return document.documentElement.classList.contains(DARK_CLASS);
}

/**
 * Wrapper to call async function to change the theme value in the server session.
 * @param {HTMLInputElement} input
 * @param {HTMLElement} toggle
 * @param {string} token
 */
function handleClickToggle(input, toggle, token) {
    toggleThemeInSession(input, toggle, token);
}

/**
 * Set theme value in server session then update elements.
 * @param {HTMLInputElement} input
 * @param {string} token
 * @returns {Promise<void>}
 */
async function toggleThemeInSession(input, toggle, token) {
    try {
        toggle.classList.add("loading");
        const { theme } = await updateTheme(input.value, token);

        swapClassesIf(
            theme === DARK_CLASS,
            document.documentElement,
            DARK_CLASS,
            LIGHT_CLASS
        );

        setThemeInputValue(input);
        styleSelects();
    } catch (error) {
        console.error("Error on updating theme: ", error);
    } finally {
        toggle.classList.remove("loading");
    }
}

/**
 * Set toggle input theme.
 * @param {HTMLInputElement} input
 */
function setThemeInputValue(input) {
    const newTheme = isDarkTheme() ? LIGHT_CLASS : DARK_CLASS;
    setInputValue(input, newTheme);
}

/**
 * Change select fields color on change event
 * since it's not possible to style option tags.
 */
export function styleSelects() {
    const selects = document.querySelectorAll(".ui-kit.ui-select");

    if (!selects || selects.length === 0) return;

    selects.forEach((select) => applySelectStyle(select));
}

/**
 * Style select field that has already been filled previously.
 * @param {HTMLElement} select
 */
export function applySelectStyle(select) {
    const selectedOption = select?.options?.[select.selectedIndex]?.value;

    if (selectedOption) {
        if (isDarkTheme()) {
            select.style.color = "white";
        } else {
            select.style.color = "black";
        }
    }
}
