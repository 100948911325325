class BaseField {
    validatorKey = "";
    element = null;
    errorElement = null;
    errors = [];

    get hasErrors() {
        return this.errors?.length !== 0;
    }

    get hasErrorElement() {
        return this.errorElement != null;
    }

    get effectiveValue() {
        return this.element.value;
    }

    get getValidatorKey() {
        return this.element.dataset.validator;
    }

    get getErrorElement() {
        return document.querySelector(
            `[data-validator-error=${this.validatorKey}]`
        );
    }

    get keyValue() {
        return [this.validatorKey, this.effectiveValue];
    }

    set setValue(value) {
        this.element.value = value;
    }

    setErrors(errors) {
        this.errors = errors;
    }

    setErrorElementText(text) {
        if (this.hasErrorElement) {
            this.errorElement.innerText = text;
        }
    }

    updateErrors(errors) {
        this.setErrors(errors);
        this.setErrorElementText(this.errors[0] ?? ".");
        this.setErrorVisibility();
    }

    setErrorVisibility() {
        if (this.hasErrorElement) {
            this.errors[0]
                ? this.errorElement.classList.remove("invisible")
                : this.errorElement.classList.add("invisible");
        }
    }

    listen(callback) {
        this.element.addEventListener("input", () => {
            callback(this);
        });

        if (this.element.classList.contains("blur-validation")) {
            this.element.addEventListener("blur", () => {
                callback(this);
            });
        }

        if (this.element?.type === "range") {
            this.element.addEventListener("click", () => {
                callback(this);
            });
        }

        if (this.element.classList.contains("autocomplete-input")) {
            this.element.addEventListener("input-autocomplete", () => {
                callback(this);
            });
        }
    }
}

export class Field extends BaseField {
    constructor(element) {
        super();
        this.element = element;
        this.validatorKey = this.getValidatorKey;
        this.errorElement = this.getErrorElement;
        this.setErrorVisibility();
        this.element.dataset.isInvalid = false;
    }
}

export class CheckboxField extends Field {
    get effectiveValue() {
        return this.element.checked;
    }
}

export class RadioField extends BaseField {
    element;
    validatorKey;
    errorElement;
    errors = [];
    /**
     *
     * @param {HTMLInputElement} fields
     */
    constructor(validatorKey, fields) {
        super();
        this.validatorKey = validatorKey;
        this.fields = fields;
        this.element = this.getCheckedOrFirst;
        this.errorElement = this.getErrorElement;
        this.setErrorVisibility();
        this.element.dataset.isInvalid = false;
    }

    get getCheckedElement() {
        return Object.values(this.fields).find((field) => field.checked);
    }

    get getCheckedOrFirst() {
        const fields = Object.values(this.fields);
        return fields.find((field) => field.checked) ?? fields[0];
    }

    get effectiveValue() {
        return this.element.checked ? this.element.value : undefined;
    }

    updateElement() {
        this.element = this.getCheckedElement;
    }

    listen(callback) {
        Object.values(this.fields).forEach((radio) => {
            radio.addEventListener("click", () => {
                this.updateElement();
                callback();
            });
        });
    }
}
