import { ExtraError } from "../constants";
import { FormValidatorExtension } from "../extension";
import { hideExtraError, showExtraError } from "./common/extra_error";
import {
    areVisitDatesUnique,
    isTimeDifferenceValid,
} from "./common/visit_repeater";

export class UniqueVisitDateExtension extends FormValidatorExtension {
    beforeValidate(field) {
        if (!field.element.closest("#visit-input-repeater")) {
            return;
        }

        const uniqueError = document.getElementById("unique-visit-error");
        const yearDiffError = document.getElementById("year-diff-error");

        if (areVisitDatesUnique("visit-input-repeater")) {
            hideExtraError(
                this.formValidator,
                uniqueError,
                ExtraError.UNIQUE_VISIT_DATES
            );
        }

        if (isTimeDifferenceValid("visit-input-repeater")) {
            hideExtraError(
                this.formValidator,
                yearDiffError,
                ExtraError.YEAR_DIFF
            );
        }
    }

    beforeSubmit() {
        const uniqueError = document.getElementById("unique-visit-error");
        const yearDiffError = document.getElementById("year-diff-error");

        if (!areVisitDatesUnique("visit-input-repeater")) {
            showExtraError(
                this.formValidator,
                uniqueError,
                ExtraError.UNIQUE_VISIT_DATES
            );
        } else if (!isTimeDifferenceValid("visit-input-repeater")) {
            showExtraError(
                this.formValidator,
                yearDiffError,
                ExtraError.YEAR_DIFF
            );
        }
    }
}
