import { isDesktopBreakpoint } from "./utils";
import Collapse from "bootstrap/js/dist/collapse";

const MOBILE_TRANSITION_CLASS = "height 0.4s ease";
const DESKTOP_TRANSITION_CLASS = "width 0.4s ease";

/**
 * Initialise sidebar collapses' appearance modifications.
 */
export function initSidebarCollapseAppearance() {
    const sidebarCollapses = document.querySelectorAll(".sidebar-section");
    const sidebarButtons = document.querySelectorAll(".sidebar-button");
    const asideNavigator = document.querySelector(".aside-navigator");
    let shown;

    if (!sidebarCollapses?.length || !asideNavigator) {
        return;
    }

    sidebarCollapses.forEach((collapse) => {
        const hideCollapse = (event) => {
            if (!collapse.contains(event.target)) {
                Collapse.getInstance(collapse)?.hide();
            }
        };

        collapse.addEventListener("show.bs.collapse", () => {
            applyTransitionOnShow(collapse);
            setCollapsePointerEvents(sidebarButtons, true);
            shown = collapse;
        });

        collapse.addEventListener("shown.bs.collapse", () => {
            applyTransitionOnShow(collapse);
            setCollapsePointerEvents(sidebarButtons, false);
            window.addEventListener("click", hideCollapse);
        });
        collapse.addEventListener("hide.bs.collapse", () => {
            removeTransitionOnHide(collapse, shown);
            window.removeEventListener("click", hideCollapse);
        });
    });

    const updateCollapse = () => {
        sidebarCollapses.forEach((collapse) => {
            toggleCollapseHorizontal(collapse);
            computeCollapseBottomPosition(
                collapse,
                asideNavigator.clientHeight
            );
        });
    };

    window.addEventListener("resize", updateCollapse);
    updateCollapse();
}

/**
 * Apply collapse transition on show.
 * @param {HTMLElement} collapse
 */
function applyTransitionOnShow(collapse) {
    collapse.style.transition = getCollapseTransition();
}

/**
 * Remove collapse transition on hide if it was triggered by toggling another collapse.
 * @param {HTMLElement} collapse
 * @param {HTMLElement|undefined} shown
 */
function removeTransitionOnHide(collapse, shown) {
    if (shown && shown?.id !== collapse.id) {
        collapse.style.transition = "none";
    }
}

/**
 * Applies a vertical (height) transition to sidebar collapses on mobile screens
 * and a horizontal (width) transition to sidebar collapses on desktop screens.
 * @param {HTMLElement} collapse
 */
function toggleCollapseHorizontal(collapse) {
    collapse.classList.toggle("collapse-horizontal", isDesktopBreakpoint());
}

/**
 * Adjust collapse bottom position according to a height value.
 * @param {HTMLElement} collapse
 * @param {number} height
 */
function computeCollapseBottomPosition(collapse, height) {
    if (!isDesktopBreakpoint()) {
        const bottom = height + 1;
        collapse.style.bottom = `${bottom}px`;
        collapse.style.maxHeight = `calc(100% - ${bottom}px)`;
    } else {
        collapse.style.bottom = "0px";
        collapse.style.maxHeight = "100%";
    }
}

/**
 * Get collapse transition style according to the screen breakpoint.
 * @returns {string}
 */
function getCollapseTransition() {
    return isDesktopBreakpoint()
        ? DESKTOP_TRANSITION_CLASS
        : MOBILE_TRANSITION_CLASS;
}

/**
 * Either add or disable collapses pointer events to avoid animation overlapping.
 * @param {NodeListOf<Element>} sidebarButtons
 * @param {boolean} disable
 */
function setCollapsePointerEvents(sidebarButtons, disable) {
    sidebarButtons.forEach((button) => {
        button.style.pointerEvents = disable ? "none" : "auto";
    });
}
