import { upperCaseFirstLetter } from "../../../utils";
import { FormValidatorExtension } from "../extension";

export class DependentFieldExtension extends FormValidatorExtension {
    getDependentId(field) {
        return field.element.dataset.dependent;
    }

    getDependentField(dependentId) {
        return [...this.formValidator.fields].find(
            (field) => field[1].validatorKey === dependentId
        )[1];
    }

    checkLinkedField(field) {
        if (!field.validatorKey.startsWith("link")) {
            const key = `link${upperCaseFirstLetter(field.validatorKey)}`;
            const link = this.formValidator.getField(key);

            if (!link) return;

            const [selectorLink, classNameLink] =
                this.formValidator.getErrorSelector(link.element);

            link.updateErrors([]);
            selectorLink.classList.remove(classNameLink);
        }
    }

    beforeValidate(field) {
        if (field.element.classList.contains("dependent-input")) {
            const dependentKey = this.getDependentId(field);
            const dependentField = this.getDependentField(dependentKey);

            const [selector, className] = this.formValidator.getErrorSelector(
                dependentField.element
            );

            const errorMessage =
                this.formValidator.getCustomErrorMessages()[dependentKey]
                    .dependent;

            if (field.effectiveValue && !dependentField.effectiveValue) {
                dependentField.updateErrors([errorMessage]);
                selector.classList.add(className);
            } else {
                dependentField.updateErrors([]);
                selector.classList.remove(className);
                this.checkLinkedField(field);
            }
        }
    }
}
