const BASE_URL = window.location.origin;

/**
 * Request to update theme value in server session.
 * @param {'light'|'dark'} theme
 * @param {string} token
 * @returns {Promise<{theme: 'light'|'dark'}|undefined>}
 */
export async function updateTheme(theme, token) {
    try {
        const res = await fetch(`${BASE_URL}/theme`, {
            method: "POST",
            headers: {
                "X-CSRFToken": token,
                "Content-type": "application/json",
            },
            body: JSON.stringify({ theme: theme }),
        });

        return await res.json();
    } catch (error) {
        console.error("API error: ", error);
    }
}
