import { FormValidatorExtension } from "../extension";
import { upperCaseFirstLetter } from "../../../utils";

export class LinkedFieldsExtension extends FormValidatorExtension {
    getLinkKey(field) {
        return `link${upperCaseFirstLetter(field.validatorKey)}`;
    }

    beforeValidate(field) {
        const key = this.getLinkKey(field);
        const linkedField = this.formValidator.getField(key);

        if (linkedField) {
            this.formValidator.validateField(linkedField);
        }
    }
}
