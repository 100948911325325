/**
 * Mathematical equation to compute percentage scaling value.
 * @param {number} range
 * @param {number} value
 * @param {number} minValue
 * @param {number} maxValue
 * @returns {number}
 */
export function computeScale(range, value, minValue, maxValue) {
    return ((value - minValue) * range) / (maxValue - minValue);
}

/**
 * Mathematical equation to compute relative scaling value.
 * @param {number} value
 * @param {number} minRelative
 * @param {number} maxRelative
 * @param {number} minValue
 * @param {number} maxValue
 * @returns {number}
 */
export function computeRelativeScale(
    value,
    minRelative,
    maxRelative,
    minValue,
    maxValue
) {
    return (
        ((value - minValue) / (maxValue - minValue)) *
            (maxRelative - minRelative) +
        minRelative
    );
}

/**
 * Convert radians to degrees.
 * @param {number} radians
 * @returns {number}
 */
export function radiansToDegrees(radians) {
    return radians * (180 / Math.PI);
}
