/**
 * JS : Input validation
 *
 * Contain : Password validation, Match password, Match email
 *
 * Product by Ocumetra
 *
 */

/**
 * Init input validation
 */
function initInputValidation() {
    const matchPasswordInputs =
        document.getElementsByClassName("match-password");

    const matchEmailInputs = document.getElementsByClassName("match-email");

    // Init all input match passwords
    for (const input of matchPasswordInputs) {
        initInputMatchPassword(input);
    }
    // Init all input match emails
    for (const input of matchEmailInputs) {
        initInputMatchEmail(input);
    }
}

/**
 * Init the input for a match password
 * @param {*} input - input match password
 * @param {*} inputVPs - all input valid passwords
 */
function initInputMatchPassword(input) {
    const confirm = document.querySelector("input#confirm");

    const other = input?.id?.includes("confirm")
        ? document.querySelector("input#password")
        : confirm;

    const NB_REQUIRED_NEED_MATCH_PASSWORD = 1;
    let nbRequirementMatchPassword = 0;

    const popUp = document.querySelector("#pop-up-match-password");

    input.addEventListener("focus", showPopUpMatchPassword);
    input.addEventListener("keyup", showPopUpMatchPassword);

    function showPopUpMatchPassword() {
        if (this.value !== "") {
            showPopUp(input, popUp);
        }
        checkMatchPassword(this.value, other);
    }

    /**
     * Check if the val corresponds to a match password and change the popUp
     * @param {*} val
     */
    function checkMatchPassword(val, other) {
        nbRequirementMatchPassword = 0;
        // Check if both password are the same
        if (val == other.value) {
            nbRequirementMatchPassword++;
        }
        // Check if the requirement for a match password is okay
        if (nbRequirementMatchPassword == NB_REQUIRED_NEED_MATCH_PASSWORD) {
            inputIsValid(confirm, popUp);
        } else {
            inputIsNotValid(confirm, popUp);
        }
    }

    // Call it when you resize the window
    window.addEventListener("resize", function () {
        setPopUpPos(input, popUp);
    });
}

/**
 * Init the input for a match email
 * @param {*} input - input match email
 */
function initInputMatchEmail(input) {
    const confirm = document.querySelector("input#confirm_email");

    const other = input?.id?.includes("confirm")
        ? document.querySelector("input#email")
        : confirm;

    // Get the popUp for match-email
    const popUp = document.querySelector("#pop-up-match-email");
    // Add event listeners
    input.addEventListener("focus", showPopUpMatchEmail);
    input.addEventListener("keyup", showPopUpMatchEmail);

    /**
     * Show the PopUp Match
     */
    function showPopUpMatchEmail() {
        // Show PopUp only when something has already been pressed
        if (this.value !== "") {
            showPopUp(confirm, popUp);
        }
        checkMatchEmail(this.value, other);
    }

    /**
     * Check if the val corresponds to a match email and change the popUp
     * @param {*} val
     */
    function checkMatchEmail(val, other) {
        // Check if both emails are the same
        if (val === other.value) {
            inputIsValid(confirm, popUp);
        } else {
            inputIsNotValid(confirm, popUp);
        }
    }

    // Call it when you resize the window
    window.addEventListener("resize", function () {
        setPopUpPos(input, popUp);
    });
}

/**
 * Show the message PopUp
 * @param {*} input
 * @param {*} popUp
 */
function showPopUp(input, popUp) {
    // Show the popUp
    popUp.style.display = "block";
    // Set the PopUp position
    setPopUpPos(input, popUp);
}

/**
 * Hide the message PopUp
 * @param {*} popUp
 */
function hidePopUp(popUp) {
    // Hide the popUp
    popUp.style.display = "none";
}

/**
 * Set the PopUp position
 * @param {*} input
 * @param {*} popUp
 */
function setPopUpPos(input, popUp) {
    const inputOffset = input.getBoundingClientRect();
    const popUpOffset = popUp.getBoundingClientRect();

    // Top = Just below the input
    const top = inputOffset.top - popUpOffset.height - 10 + window.scrollY;
    // Left = middle of the input
    const left =
        inputOffset.left + inputOffset.width / 2 - popUpOffset.width / 2;
    // Set the popup position
    popUp.style.top = top + "px";
    popUp.style.left = left + "px";
}

/**
 * Mark input value as valid and hide pop-up.
 * @param {*} input
 * @param {*} popUp
 */
function inputIsValid(input, popUp) {
    input.classList.add("input-is-valid");
    hidePopUp(popUp);
}
/**
 * Mark input value as invalid and show pop-up.
 * @param {*} input
 * @param {*} popUp
 */
function inputIsNotValid(input, popUp) {
    input.classList.remove("input-is-valid");
    showPopUp(input, popUp);
}
// Launch the libary to the load
window.addEventListener("load", function () {
    initInputValidation();
});
