import { FormValidatorExtension } from "../extension";

export class UnionExtension extends FormValidatorExtension {
    isSphere(key) {
        return key.startsWith("sphere");
    }

    isAxial(key) {
        return key.startsWith("axial");
    }

    getSphereFields() {
        return [...this.formValidator.fields].filter((field) =>
            this.isSphere(field[1].validatorKey)
        );
    }

    getAxialFields() {
        return [...this.formValidator.fields].filter((field) =>
            this.isAxial(field[1].validatorKey)
        );
    }

    shouldCheckUnion(field) {
        return (
            this.isSphere(field.validatorKey) ||
            this.isAxial(field.validatorKey)
        );
    }

    beforeValidate(field) {
        if (this.shouldCheckUnion(field)) {
            const sphereFields = this.getSphereFields();
            const axialFields = this.getAxialFields();

            const isSphereEmpty = sphereFields.every(
                ([, field]) => !field.effectiveValue
            );
            const isAxialFilled = axialFields.every(
                ([, field]) => field.effectiveValue
            );

            if (isSphereEmpty && isAxialFilled) {
                sphereFields.forEach(([, _field]) => {
                    const errorSelector = this.formValidator.getErrorSelector(
                        _field.element
                    );
                    this.formValidator.handleSuccessfulFieldValidation(
                        _field,
                        errorSelector
                    );
                });
            }
        }
    }
}
