import { AxialExtension } from "./collection/axial_extension";
import { ConfirmFieldExtension } from "./collection/confirm_extension";
import { DependentFieldExtension } from "./collection/dependent_extension";
import { LinkedFieldsExtension } from "./collection/link_extension";
import { LoaderExtension } from "./collection/loader_extension";
import { VisitRepeaterExtension } from "./collection/visit_repeater_extension";
import { UnionExtension } from "./collection/union_extension";
import { TreatmentRepeaterExtension } from "./collection/treatment_repeater_extension";
import { TreatmentStopDateExtension } from "./collection/treatment_stop_date_extension";
import { TreatmentStartDateExtension } from "./collection/treatment_start_date_extension";
import { UniqueVisitDateExtension } from "./collection/unique_visit_date_extension";

export const EXTENSIONS = Object.freeze({
    link: LinkedFieldsExtension,
    loader: LoaderExtension,
    confirm: ConfirmFieldExtension,
    dependent: DependentFieldExtension,
    axial: AxialExtension,
    union: UnionExtension,
    visitRepeater: VisitRepeaterExtension,
    treatmentRepeater: TreatmentRepeaterExtension,
    stopTreatmentDate: TreatmentStopDateExtension,
    startTreatmentDate: TreatmentStartDateExtension,
    uniqueVisitDate: UniqueVisitDateExtension,
});
