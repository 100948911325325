/**
 * JS : Error Validation System
 *
 * Product by Ocumetra
 *
 */
import $ from "jquery";

/**
 * Check if all required fields are valid or not in the page
 * @returns {boolean} All required fields are valid or not
 */
export function validator() {
    // Init
    let isV = true;
    // Focusout all required fields
    $("[required]").blur();
    // Check if each required fields are valid
    $("[required]").each(function () {
        if (!isValid($(this))) {
            // Add error on each required field
            $(this).addClass("has-error");
            // Display PopUp error only for the first error
            if (isV) {
                displayErrorOnInput($(this));
                isV = false;
            }
        }
    });
    return isV;
}

/**
 * Check if a required field is valid or not according to its type
 * @param {*} input - Any required input filed
 * @returns {boolean}
 */
function isValid(input) {
    // Check if there is already an error
    if ($(input).hasClass("has-error")) {
        return false;
    }
    // Input must be visible to display an error
    if ($(input).is(":visible")) {
        // If the input type = radio
        if ($(input).attr("type") == "radio") {
            const inputName = $(input).attr("name");
            // Check if one of the button in the group (by the name) is checked or not
            return $("input[name=" + inputName + "]:checked").length > 0;
        }

        if ($(input).is("select")) {
            return $(input).find(":selected").val().length > 0;
        }
        // Other types of input
        // Check if the input value is not null (so that the length is > 0)
        return $(input).val().length > 0;
    }
    return true;
}

/**
 * Display an error on the input
 * @param {*} input - Input where the error will be generated
 * @param {string} txt - The text displayed on the error (not mandatory)
 */
export function displayErrorOnInput(input, txt) {
    // Get or Set the error message
    if (txt) {
        setErrorMessage(input, txt);
    } else if ($(input).data("error-msg")) {
        txt = $(input).data("error-msg");
    } else {
        txt = $("#error-msgs").data("fallback-error");
    }
    // Add error on the input
    $(input).addClass("has-error");
    // Can create only one error PopUp
    if ($(".pop-up-error").length == 0) {
        // The pop-up content
        createErrorPopUpTitle(input, txt);
    }
}

/**
 * Set the error messsage
 * @param {*} input - Input with the data-error-msg
 * @param {string} message - The error message
 */
function setErrorMessage(input, message) {
    $(input).data("error-msg", message);
}

/**
 * Create the Error PopUp with an error message
 * @param {*} input - Give the position where to create the error popup
 * @param {string} txt - The message inside the popup
 */
function createErrorPopUpTitle(input, txt) {
    // The PopUp
    const popUp =
        "<div class='pop-up-error'>" +
        "<div class='pointer'></div>" +
        "<p>" +
        txt +
        "</p>" +
        "</div>";
    // Add pop-up in the page
    $("#tools-content").append(popUp);
    // Set the PopUp position
    setPopUpPos(input);
    // Add an event when we click on the pop-up, this one is deleted
    $(".pop-up-error").on("click", function () {
        deleteErrorPopUp();
    });
    // Display PopUp with fadeIn animation
    $(".pop-up-error").fadeIn(500);
}

/**
 * Delete the Error PopUp with fadeOut animation
 */
function deleteErrorPopUp() {
    $(".pop-up-error").fadeOut(500, function () {
        $(this)[0].remove();
    });
}

/**
 * Get the TOP and LEFT position of the PopUp
 * @param {*} input - Get its position
 * @param {popUp} popUp - Get its width
 */
function getPosPopUp(input, popUp) {
    // Top = Just below the input
    const top = $(input).offset().top + $(input).outerHeight() + 10;
    // Left = middle of the input
    const left =
        $(input).offset().left +
        $(input).outerWidth() / 2 -
        $(popUp).outerWidth() / 2;
    return { top, left };
}

/**
 * Set the PopUp position
 * @param {*} input - Need for its position
 */
function setPopUpPos(input) {
    const popUp = $(".pop-up-error");
    const pos = getPosPopUp(input, popUp);
    $(popUp).css({ top: pos.top + "px", left: pos.left + "px" });
}

/**
 * Change the PopUp position when we resize the window
 */
function resizePopUp() {
    const inputError = $(".has-error");
    if (inputError.length > 0) {
        setPopUpPos(inputError);
    }
}

/**
 * For each input required : add input event and remove error
 */
function initEventRequiredInput() {
    // Add input event on each required field
    $("[required]").on("input", function () {
        // If the input type = radio apply a diffrent behavior
        if ($(this).attr("type") == "radio") {
            // Remove error on all radio buttons of the same group (=> same name)
            $("input[name=" + $(this).attr("name") + "]").each(function () {
                removeError(this);
            });
            // Other type
        } else {
            removeError(this);
        }
    });
}

/**
 * Remove the error on the input and delete the PopUp
 * @param {*} input - Removal of the error on the input
 * @param {string} txt - Set the message on the input (not mandatory)
 */
export function removeError(input, txt) {
    // Set the error message if it exists
    if (txt) {
        setErrorMessage(input, txt);
    }
    // Remove only when there is an error
    if ($(input).hasClass("has-error")) {
        $(input).removeClass("has-error");
        deleteErrorPopUp();
    }
}

initEventRequiredInput();

$(window).resize(function () {
    resizePopUp();
});
