const LISTERNERS = {};
const MOBILE_TOOLTIP_BREAKPOINT = 768;

/**
 * Initialize custom tooltips.
 */
export function initUiKitTooltips() {
    const tooltips = document.querySelectorAll(".ui-kit-tooltip");

    tooltips.forEach((tooltip) => {
        handleClickTooltip(tooltip);
        handleMouseOutTooltip(tooltip);
    });

    handleResize(tooltips);
    handleDismissMobileTooltip();
}

/**
 * Handle tooltips click event.
 * @param {HTMLElement} tooltip
 */
function handleClickTooltip(tooltip) {
    const trigger = document.getElementById(tooltip.id + "-trigger");

    if (!trigger) {
        throw Error(`Tooltip trigger not found (${tooltip.id})`);
    }

    trigger.addEventListener("click", () => {
        if (isMobileTooltipBreakpoint()) {
            handleMobileTooltip(tooltip);
        }
    });

    trigger.addEventListener("mouseover", () => {
        if (!isMobileTooltipBreakpoint()) {
            toggleTooltip(tooltip);
            setTooltipPosition(tooltip, trigger);
        }
    });
}

/**
 * Toggle tooltip display state.
 * @param {HTMLElement} tooltip
 */
function toggleTooltip(tooltip) {
    tooltip.classList.add("show");
    listenMouseOver(tooltip);
}

/**
 * Closes tooltip if mouse is out of tooltip area.
 * @param {HTMLElement} tooltip
 * @param {MouseEvent} event
 */
function onMouseOverTooltip(tooltip, event) {
    const { target } = event;

    if (!isTooltip(target) && !isTooltipContainer(target, tooltip.id)) {
        closeTooltip(tooltip);
    }
}

/**
 * Add/Remove mouseover listener from tooltip.
 * @param {HTMLElement} tooltip
 */
function listenMouseOver(tooltip) {
    if (tooltip.classList.contains("show")) {
        LISTERNERS[tooltip.id] = (event) => onMouseOverTooltip(tooltip, event);
        window.addEventListener("mouseover", LISTERNERS[tooltip.id]);
    } else {
        window.removeEventListener("mouseover", LISTERNERS[tooltip.id]);
        delete LISTERNERS[tooltip.id];
    }
}

/**
 * Close tooltip.
 * @param {HTMLElement} tooltip
 */
function closeTooltip(tooltip) {
    tooltip.classList.remove("show");
    listenMouseOver(tooltip);
}

/**
 * Set top position for tooltip.
 * @param {HTMLElement} tooltip
 * @param {HTMLElement} trigger
 */
function setTooltipPosition(tooltip, trigger) {
    const arrowOffset = 9;
    const top = trigger.offsetTop + trigger.offsetHeight + arrowOffset;
    tooltip.style.top = `${top}px`;
}

/**
 * Set tooltip position as screen gets resized.
 * @param {NodeListOf<Element>} tooltips
 */
function handleResize(tooltips) {
    const mobileTooltip = document.querySelector(".ui-kit-tooltip-mobile");

    window.addEventListener("resize", () => {
        tooltips.forEach((tooltip) => {
            const trigger = getTooltipTrigger(tooltip);
            setTooltipPosition(tooltip, trigger);
        });

        if (!isMobileTooltipBreakpoint()) {
            hideMobileTooltip(mobileTooltip);
        }
    });
}

/**
 * Close tooltip when user click outside of it.
 * @param {NodeListOf<Element>} tooltips
 */
function handleMouseOutTooltip(tooltip) {
    closeTooltip(tooltip);
}

/**
 * Get tooltip trigger element.
 * @param {HTMLElement} tooltip
 * @returns {HTMLElement}
 */
function getTooltipTrigger(tooltip) {
    return document.getElementById(tooltip.id + "-trigger");
}

/**
 * Check if element is tooltip.
 * @param {HTMLElement} element
 */
function isTooltip(element) {
    const tooltipClasses = ["ui-kit-tooltip", "tooltip-trigger"];

    return tooltipClasses.some(
        (className) =>
            element.classList.contains(className) ||
            element.closest(`.${className}`)
    );
}

/**
 * Check if element if a tooltip container.
 * @param {HTMLElement} element
 * @param {string} tooltipId
 */
function isTooltipContainer(element, tooltipId) {
    const containerId = `${tooltipId}-container`;
    return element?.id === containerId || !!element.closest(`#${containerId}`);
}

/**
 * Check if the viewport width is below the mobile tooltip breakpoint.
 * @returns {boolean}
 */
function isMobileTooltipBreakpoint() {
    return document.documentElement.clientWidth < MOBILE_TOOLTIP_BREAKPOINT;
}

/**
 *  Handle the display of the mobile tooltip, which is a single element in the body
 *  that receives the current active tooltip data.
 * @param {HTMLElement} targetTooltip - It is the current active tooltip, its content
 *                                      will be cloned into the mobile tooltip.
 */
function handleMobileTooltip(targetTooltip) {
    const mobileTooltip = document.querySelector(".ui-kit-tooltip-mobile");
    setMobileTooltipContent(mobileTooltip, targetTooltip);
    showMobileTooltip(mobileTooltip);
}

/**
 * Get the content of the current active tooltip and clone it into the mobile tooltip.
 * @param {HTMLElement} mobileTooltip
 * @param {HTMLElement} targetTooltip
 */
function setMobileTooltipContent(mobileTooltip, targetTooltip) {
    const mobileTooltipTitle = mobileTooltip.querySelector(
        ".tooltip-mobile-title"
    );
    const mobileTooltipContent = mobileTooltip.querySelector(
        ".mobile-tooltip-content"
    );
    const targetContent = targetTooltip
        .querySelector(".tooltip-content")
        .cloneNode(true);

    mobileTooltipTitle.innerText = targetTooltip.dataset.title;
    mobileTooltipContent.appendChild(targetContent);
}

/**
 * Shows the mobile tooltip element and blocks body scroll.
 * @param {HTMLElement} mobileTooltip
 */
function showMobileTooltip(mobileTooltip) {
    mobileTooltip.classList.toggle("show");
    document.body.classList.add("overflow-hidden");
}

/**
 * Add listerner to handle closing the mobile tooltip.
 */
function handleDismissMobileTooltip() {
    const mobileTooltip = document.querySelector(".ui-kit-tooltip-mobile");

    if (!mobileTooltip) {
        return;
    }

    const mobileTooltipBackdrop = document.querySelector(
        ".tooltip-mobile-backdrop"
    );
    const mobileTooltipButton = mobileTooltip.querySelector(
        ".tooltip-mobile-button"
    );

    [mobileTooltipBackdrop, mobileTooltipButton].forEach((element) => {
        element.addEventListener("click", () => {
            hideMobileTooltip(mobileTooltip);
        });
    });
}

/**
 * Hide mobile tooltip and removes the content in it.
 * @param {HTMLElement} mobileTooltip
 */
function hideMobileTooltip(mobileTooltip) {
    const mobileTooltipContent = mobileTooltip.querySelector(
        ".mobile-tooltip-content"
    );

    const mobileTooltipTitle = mobileTooltip.querySelector(
        ".tooltip-mobile-title"
    );

    const content = mobileTooltipContent.querySelector(".tooltip-content");

    if (content) {
        mobileTooltipContent.removeChild(content);
    }

    mobileTooltipTitle.innerText = "";
    mobileTooltip.classList.remove("show");
    document.body.classList.remove("overflow-hidden");
}
