import { state } from "./state";
import {
    formatISODate,
    isSafari,
    parseFormat,
    roundIntToQuarter,
    snakeToCamelCase,
} from "./utils";

function removeDateStyleIfSafari() {
    document.getElementsByName("visit-date").forEach((input) => {
        if (isSafari()) {
            input.classList.remove("meye-date-picker");
        }
    });
}

/**
 * Init element repeater functionality.
 */
export function initRepeater() {
    removeDateStyleIfSafari();

    const addButtons = document.querySelectorAll(".repeater-add");

    addButtons.forEach((addButton) => {
        const count = state(1);
        setupRepeater(addButton, count);
    });
}

function setupRepeater(addButton, count) {
    const { repeater } = addButton.dataset;

    const repeaterWrapper = document.getElementById(repeater);
    const modalElement = document.querySelector(
        `[data-target=${repeaterWrapper.id}]`
    );

    addButton.addEventListener("click", (event) => {
        if (!repeaterWrapper) {
            throw Error("Repeater not found");
        }

        const trigger = () => {
            handleAddRepeaterItem(event, repeaterWrapper, count);
        };

        const addEvent = new CustomEvent("add-repeater-row", {
            cancelable: true,
            detail: { trigger },
        });
        addButton.dispatchEvent(addEvent);

        if (!addEvent.defaultPrevented) {
            trigger();
        }
    });

    handleRepeaterFocus(repeaterWrapper);
    validateDates(repeaterWrapper);
    modalElement.addEventListener("shown.bs.modal", (e) =>
        handleRemoveRepeaterItem(e, modalElement)
    );
}

/**
 * Triggers when remove item modal is shown, removes repeater item entry
 * if user clicks on confirm action and clear listener on dismiss.
 * @param {Event} event
 */
function handleRemoveRepeaterItem(event, modalElement) {
    const removeItem = modalElement.querySelector(".remove-repeater-item");
    const item = event.relatedTarget.closest(".repeater-item");

    const handleClick = () => {
        const triggerRemove = () => {
            item.classList.add("exiting");
        };

        const removeEvent = new CustomEvent("remove-repeater-row", {
            cancelable: true,
            detail: { trigger: triggerRemove },
        });

        removeItem.dispatchEvent(removeEvent);

        if (!removeEvent.defaultPrevented) {
            triggerRemove();
        }

        item.addEventListener("animationend", () => {
            const triggerRemoved = () => {
                item.remove();
            };

            const removedEvent = new CustomEvent("removed-repeater-row", {
                cancelable: true,
                detail: { trigger: triggerRemoved },
            });

            removeItem.dispatchEvent(removedEvent);

            if (!removedEvent.defaultPrevented) {
                triggerRemoved();
            }
        });
    };

    removeItem.addEventListener("click", handleClick);

    event.target.addEventListener("hidden.bs.modal", () => {
        removeItem.removeEventListener("click", handleClick);
    });
}

/**
 * @param {HTMLElement} repeaterWrapper
 */
function handleAddRepeaterItem(
    event,
    repeaterWrapper,
    count,
    refItem = undefined
) {
    const { insertAt } = repeaterWrapper?.dataset ?? "bottom";

    const [getCount, setCount] = count;

    const baseItem = repeaterWrapper.querySelector(".repeater-item-base");
    const item = refItem ? refItem : baseItem;

    const inputs = item.querySelectorAll("input,select");
    const newItem = createNewRepeaterItem(item, count);

    const removeRepeaterModal = newItem.querySelector(".remove-repeater-modal");
    removeRepeaterModal.classList.remove("invisible");

    newItem.classList.add("entering");

    if (insertAt === "top") {
        const [first] = [...repeaterWrapper.children];
        repeaterWrapper.insertBefore(newItem, first);
    } else {
        repeaterWrapper.insertBefore(newItem, baseItem);
    }

    const itemValues = generateRepeaterItemValues(newItem.id, inputs);
    insertRepeaterItemValues(newItem, itemValues);

    cleanBaseRepeaterItem(inputs, baseItem);
    baseItem.classList.remove("focused-inputs");
    setCount(getCount() + 1);
}

/**
 * @param {HTMLElement} baseItem
 * @param {[Function, Function]} count
 */
function createNewRepeaterItem(baseItem, count) {
    const [getCount] = count;

    const newItem = document.createElement("div");
    const baseId = baseItem.id.split("-").slice(0, -1).join("-");

    newItem.innerHTML = baseItem.innerHTML;
    const classes = [...baseItem.classList, "row-display"].join(" ");
    const newClasses = classes.replace("repeater-item-base", "repeater-item");

    newItem.setAttribute("class", newClasses);

    newItem.id = `${baseId}-${getCount()}`;
    newItem.innerHTML = baseItem.innerHTML;

    const inputs = newItem.querySelectorAll("input,select");

    inputs.forEach((input) => {
        const error = newItem.querySelector(`#${input.name}-error-0`);

        if (error) {
            error.id = `${input.name}-error-${getCount()}`;
        }

        input.id = `${input.name}-${getCount()}`;
        input.classList.remove("fw-bold");
    });

    const errors = newItem.querySelectorAll(".error-red");

    errors.forEach((error) => {
        if (error.id) {
            error.id = `${error.id}-${getCount()}`;
        }
        error.dataset.validatorError = "";
    });

    return newItem;
}

function generateRepeaterItemValues(id, inputs) {
    return [...inputs].reduce(
        (prev, curr) => {
            const [value, unit] = parseFormat(curr.dataset.format, curr.value);
            prev[curr.name] = value ? `${value} ${unit}`.trim() : "-";
            return prev;
        },
        { id }
    );
}

/**
 * Insert all row values in the new repeater item.
 * @param {HTMLElement} item
 * @param {object} values
 */
function insertRepeaterItemValues(item, values) {
    const inputs = item.querySelectorAll("input,select");

    inputs.forEach((input) => {
        if (input.type === "number") {
            input.setAttribute("type", "text");
        }

        input.readOnly = true;
        input.classList.add("input-readonly");
        input.value = values[input.name];
    });
}

/**
 * Reset input values to initial state and unfocus row.
 * @param {NodeListOf<HTMLInputElement>} inputs
 * @param {HTMLElement} baseItem
 */
function cleanBaseRepeaterItem(inputs, baseItem) {
    baseItem.blur();

    inputs.forEach((input) => {
        if (input.tagName === "SELECT") {
            input.selectedIndex = 0;
        } else {
            input.value = "";
        }
    });
}

/**
 * Handle focus repeater items.
 * @param {HTMLElement} repeaterWrapper
 */
function handleRepeaterFocus(repeaterWrapper) {
    const baseItem = repeaterWrapper.querySelector(".repeater-item-base");
    const inputs = baseItem.querySelectorAll("input,select");

    const addFocusedInputsClass = () => {
        baseItem.classList.add("focused-inputs");
    };

    inputs.forEach((input) => {
        input.addEventListener("input", addFocusedInputsClass);
        input.addEventListener("focus", addFocusedInputsClass);

        input.addEventListener("focusout", () => {
            if (!input.value) {
                return;
            }

            const [value] = parseFormat(input.dataset.format, input.value);

            if (input.getAttribute("step") === "0.25") {
                input.value = roundIntToQuarter(value).toString();
            } else if (
                Number.isInteger(parseFloat(input.getAttribute("step")))
            ) {
                input.value = parseInt(value);
            } else {
                input.value = value;
            }
        });
    });
}

/**
 *
 * @param {HTMLElement} repeaterWrapper
 */
function validateDates(repeaterWrapper) {
    const dateInputs = repeaterWrapper.querySelectorAll("input[type=date]");
    const dobInputs = document.querySelectorAll(".date-dob");

    window.addEventListener("valid-dob", () => {
        const dateObject = [...dobInputs].reduce((prev, curr) => {
            prev[snakeToCamelCase(curr.name)] = curr.value;
            return prev;
        }, {});

        const { dobDd, dobMm, dobYyyy } = dateObject;
        const dobDate = new Date(dobYyyy, dobMm - 1, dobDd);

        const minDate = new Date(
            dobDate.setFullYear(dobDate.getFullYear() + 6)
        );

        dateInputs.forEach((input) => {
            input.min = formatISODate(minDate);
        });
    });
}
