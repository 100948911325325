export class FormValidatorExtension {
    constructor() {
        this.formValidator = null;
    }

    init(formValidator) {
        this.formValidator = formValidator;
    }

    /**
     * Runs before field validation
     * @returns {boolean}
     */
    beforeValidate() {
        return true;
    }

    /**
     * Runs after field validation.
     * @returns {boolean}
     */
    afterValidate() {
        return true;
    }

    /**
     * Runs before form submit.
     * @returns {boolean}
     */
    beforeSubmit() {
        return true;
    }

    /**
     * Runs after form submit.
     * @returns {boolean}
     */
    afterSubmit() {
        return true;
    }
}
