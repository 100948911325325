import { areAllValuesPresent, parseFormat } from "../../../utils";
import { FormValidator } from "../../form_validator";
import { ExtraError } from "../constants";
import { FormValidatorExtension } from "../extension";
import {
    addErrorClass,
    hideExtraError,
    removeErrorClass,
    showExtraError,
} from "./common/extra_error";
import {
    areVisitDatesUnique,
    disableEmptyRow,
    getRepeaterRows,
    hasCorrectAgeRange,
    hasEnoughRows,
    isBaseRowEmpty,
    isTimeDifferenceValid,
} from "./common/visit_repeater";

export class VisitRepeaterExtension extends FormValidatorExtension {
    init(formValidator) {
        this.formValidator = formValidator;
        this.uniqueError = document.getElementById("unique-visit-error");
        this.visitsError = document.getElementById("visits-error");
        this.yearDiffError = document.getElementById("year-diff-error");
        this.ageRangeError = document.getElementById("age-range-error");
        this.minRows = Number(this.visitsError.dataset.min);
    }

    get validations() {
        return new Map([
            [
                ExtraError.VISIT_COUNT,
                {
                    validate: () => this.isSphereAndAxialValid(),
                    error: this.visitsError,
                },
            ],
            [
                ExtraError.YEAR_DIFF,
                {
                    validate: () =>
                        isTimeDifferenceValid("visit-input-repeater"),
                    error: this.yearDiffError,
                },
            ],
            [
                ExtraError.UNIQUE_VISIT_DATES,
                {
                    validate: () => areVisitDatesUnique("visit-input-repeater"),
                    error: this.uniqueError,
                },
            ],
            [
                ExtraError.AGE_RANGE,
                {
                    validate: () => hasCorrectAgeRange(),
                    error: this.ageRangeError,
                },
            ],
        ]);
    }

    allValid() {
        return [...this.validations.values()].every(({ validate }) =>
            validate()
        );
    }

    format(list, format) {
        return [...list].map((item) =>
            item.value !== "-" && item.value !== ""
                ? parseFormat(format, item.value, true)?.[0]
                : undefined
        );
    }

    generateFieldMap(rows) {
        return rows.reduce((prev, curr) => {
            const date = curr.querySelector("input[name=visit-date]");
            const spheres = curr.querySelectorAll("[name^=sphere]");
            const axials = curr.querySelectorAll("[name^=axial-length]");

            prev[date.value] = {
                sphere: this.format(spheres, "0.00D"),
                axial: this.format(axials, "0.00mm"),
            };

            return prev;
        }, {});
    }

    hasValidRows(fieldMap) {
        // eslint-disable-next-line prefer-destructuring
        const [, { sphere: sphere0, axial: axial0 }] = fieldMap[0];
        // eslint-disable-next-line prefer-destructuring
        const [, { sphere: sphere1, axial: axial1 }] = fieldMap[1];

        const hasValidSpheres =
            areAllValuesPresent(sphere0) && areAllValuesPresent(sphere1);
        const hasValidAxials =
            areAllValuesPresent(axial0) && areAllValuesPresent(axial1);

        return hasValidSpheres || hasValidAxials;
    }

    validateFieldMap(fieldMap) {
        const res = Object.values(fieldMap)
            .slice(0, this.minRows)
            .every((field) => {
                const [, { sphere, axial }] = field;

                const hasValidSpheres = areAllValuesPresent(sphere);
                const hasValidAxials = areAllValuesPresent(axial);

                return hasValidSpheres || hasValidAxials;
            });

        return res;
    }

    isSphereAndAxialValid() {
        const rows = getRepeaterRows("visit-input-repeater", false);
        const fieldMap = this.generateFieldMap(rows);

        const sortedFieldMap = Object.entries(fieldMap)
            .sort(([keyA], [keyB]) => new Date(keyB) - new Date(keyA))
            .slice(0, this.minRows);

        if (sortedFieldMap.length === this.minRows) {
            return this.validateFieldMap(sortedFieldMap);
        }

        return false;
    }

    beforeValidate(field) {
        if (!field.element.closest("#visit-input-repeater")) {
            return;
        }

        const rowValidator = FormValidator.getInstance("score-row-form");
        const rowContainer = document.getElementById("data-repeater-container");
        const [isEmpty] = isBaseRowEmpty("visit-input-repeater");

        this.validations.forEach(({ validate, error }, key) => {
            if (validate()) {
                hideExtraError(this.formValidator, error, key);
            }
        });

        if (this.allValid()) {
            removeErrorClass(rowContainer, "extra-error");
        }

        if (isEmpty || rowValidator.validateForm(true)) {
            this.formValidator.removeExtraError(ExtraError.VISIT_DATA);
        }
    }

    beforeSubmit() {
        const rowValidator = FormValidator.getInstance("score-row-form");
        const rowContainer = document.getElementById("data-repeater-container");
        const [isEmpty] = isBaseRowEmpty("visit-input-repeater");

        if (!isEmpty || !hasEnoughRows("visit-input-repeater", this.minRows)) {
            if (!rowValidator.validateForm(true)) {
                this.formValidator.addExtraError(ExtraError.VISIT_DATA);
            } else {
                this.formValidator.removeExtraError(ExtraError.VISIT_DATA);
            }
        }

        this.validations.forEach(({ validate, error }, key) => {
            if (!validate()) {
                showExtraError(this.formValidator, error, key);
            }
        });

        if (this.allValid()) {
            disableEmptyRow(this.formValidator, "visit-input-repeater");
        } else {
            addErrorClass(rowContainer, "extra-error");
        }
    }
}
