import { demographicPatientDataWithTreatmentSchema } from "./common";

export const buildNewScoreSchema = (errorObject) => {
    return demographicPatientDataWithTreatmentSchema(errorObject).omit({
        visitDate: true,
    });
};

export const buildUpdateScoreSchema = (errorObject) => {
    return demographicPatientDataWithTreatmentSchema(errorObject).pick({
        hasUsedTreatment: true,
    });
};

export const buildUseScoreSchema = (errorObject) => {
    return demographicPatientDataWithTreatmentSchema(errorObject).partial();
};
