import Tab from "bootstrap/js/dist/tab";
import { initThenListen, swapClasses } from "./utils";

/**
 * Execute callback for a given list of linked elements.
 * @param {HTMLElement} element
 * @param {string} selector
 * @param {(HTMLElement) => any} callback
 */
export function handleLinkedElements(element, selector, callback) {
    const { linkId } = element.dataset;

    const linkedElements = document.querySelectorAll(
        `${selector}[data-link-id=${linkId}]`
    );

    linkedElements.forEach((linkedElement) => {
        callback(linkedElement);
    });
}

/**
 * Init linked toggle input elements.
 */
export function initLinkedToggles() {
    const linkedToggles = document.querySelectorAll(
        ".linked-toggle-input input"
    );

    linkedToggles.forEach((toggle) => {
        toggle.addEventListener("input", () => checkLinkedToggles(toggle));
    });
}

/**
 * Init linked tab input elements.
 */
export function initLinkedTabInputs() {
    const linkedTabInputs = document.querySelectorAll(
        ".linked-tab-input input"
    );

    linkedTabInputs.forEach((tabInput) =>
        initThenListen(tabInput, tabInput.checked, "input", setLinkedTabInputs)
    );
}

/**
 * Check all linked toggles.
 * @param {HTMLElement} toggle
 */
function checkLinkedToggles(toggle) {
    const { toggleIndex } = toggle.dataset;

    handleLinkedElements(
        toggle,
        ".linked-toggle-input input",
        (linkedToggle) => {
            const decoration = linkedToggle
                .closest(".animated-buttons-container")
                .querySelector(".button-decoration");
            linkedToggle.checked = true;

            toggleIndex === "0"
                ? swapClasses(decoration, "first-selected", "second-selected")
                : swapClasses(decoration, "second-selected", "first-selected");
        }
    );
}

/**
 * Show all linked tabs.
 * @param {HTMLElement} tabInput
 */
function setLinkedTabInputs(tabInput) {
    handleLinkedElements(
        tabInput,
        ".linked-tab-input input",
        (linkedTabInput) => {
            new Tab(linkedTabInput).show();
        }
    );
}
