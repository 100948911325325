import { computeRelativeScale, radiansToDegrees } from "./math";

/**
 * Initialize arc range input.
 */
export function initArcRangeInput() {
    const arcRangeInputs = document.querySelectorAll(
        ".arc-range-input:not(.arc-reverse)"
    );

    if (!arcRangeInputs?.length) {
        return;
    }

    arcRangeInputs.forEach((arcRangeInput) =>
        handleArcRangeInput(arcRangeInput)
    );
}

/**
 * Handle arc range inputs events.
 * @param {HTMLInputElement} arcRangeInput
 */
function handleArcRangeInput(arcRangeInput) {
    const rangeField = arcRangeInput.querySelector("input[type=range]");
    const inputField = document.querySelector(
        `#${rangeField.id.split("-")[0]}-input`
    );
    const arcShape = arcRangeInput.querySelector(".arc-shape");
    const arcPointer = arcShape.querySelector(".arc-pointer");

    const reverseArcRangeInput =
        arcRangeInput.parentElement.parentElement.querySelector(".arc-reverse");
    const reverseShape = reverseArcRangeInput.querySelector(".arc-shape");
    const reversePointer = reverseArcRangeInput.querySelector(".arc-pointer");

    const events = ["mousemove", "touchmove", "mouseup"];
    const enableDraggingEvents = ["mousedown", "touchstart"];
    const disableDraggingEvents = ["mouseup", "touchend"];
    let isDragging = false;
    let isDraggingReverse = false;

    if (rangeField.value) {
        [arcPointer, reversePointer].forEach((pointer) => {
            pointer.style.transform = `rotate(${computeDegreeRelativeValue(
                rangeField.value,
                0,
                180,
                5
            )}deg)`;
        });
    }

    enableDraggingEvents.forEach((eventType) => {
        window.addEventListener(eventType, (event) => {
            if (
                event.target.classList.contains("arc-reverse") ||
                event.target.closest(".arc-reverse")
            ) {
                isDraggingReverse = true;
            } else {
                isDragging = true;
            }
        });
    });

    disableDraggingEvents.forEach((eventType) => {
        window.addEventListener(eventType, () => {
            isDragging = false;
            isDraggingReverse = false;
        });
    });

    inputField.addEventListener("input", (event) => {
        const value = parseInt(event.target.value);
        setArcRangeInputValue(inputField, rangeField, value);

        [arcPointer, reversePointer].forEach((pointer) => {
            pointer.style.transform = `rotate(${computeDegreeRelativeValue(
                rangeField.value,
                0,
                180,
                5
            )}deg)`;
        });
    });

    inputField.addEventListener("focusout", () => {
        inputField.value = parseInt(inputField.value);
    });

    events.forEach((eventType) => {
        [arcRangeInput, reverseArcRangeInput].forEach((arcInput) => {
            arcInput.addEventListener(eventType, (event) => {
                const isReverse = arcInput.classList.contains("arc-reverse");
                const checkDragging = isReverse
                    ? isDraggingReverse
                    : isDragging;

                if (!checkDragging) {
                    return;
                }

                const [centerX, centerY] = computeArcInputCenter(
                    isReverse ? reverseShape : arcShape,
                    isReverse ? reversePointer : arcPointer,
                    isReverse
                );

                const mouseY = event?.touches?.[0].clientY ?? event.clientY;
                const mouseX = event?.touches?.[0].clientX ?? event.clientX;

                const degrees = computeArcPointerDegreesAngle(
                    mouseY,
                    mouseX,
                    centerY,
                    centerX,
                    5,
                    isReverse
                );

                const rangeValue = computeArcRelativeValue(degrees, 0, 180, 5);
                setArcRangeInputValue(inputField, rangeField, rangeValue);

                arcPointer.style.transform = `rotate(${degrees}deg)`;
                reversePointer.style.transform = `rotate(${degrees}deg)`;
            });
        });
    });
}

/**
 * Find center of arc input relative to the page.
 * @param {HtmlElement} arcShape
 * @param {HTMLElement} arcPointer
 * @returns {number[]}
 */
function computeArcInputCenter(arcShape, arcPointer, reverse = false) {
    const arcPointerBox = arcShape.getBoundingClientRect();

    const centerPoint = window.getComputedStyle(arcPointer).transformOrigin;
    const [arcCenterX, arcCenterY] = centerPoint.split(" ");

    const arcPageRelativeCenterX = arcPointerBox.left + parseInt(arcCenterX);
    const arcPageRelativeCenterY = reverse
        ? arcPointerBox.top + parseInt(arcCenterY)
        : arcPointerBox.bottom - parseInt(arcCenterY);

    return [arcPageRelativeCenterX, arcPageRelativeCenterY];
}

/**
 * Compute angle in degrees in relation to the page and mouse position.
 * @param {number} mouseY
 * @param {number} mouseX
 * @param {number} arcCenterX
 * @param {number} arcCenterY
 * @param {number} step
 * @returns {number}
 */
function computeArcPointerDegreesAngle(
    mouseY,
    mouseX,
    arcCenterY,
    arcCenterX,
    step = 1,
    reverse = false
) {
    const radians = Math.atan2(mouseY - arcCenterY, mouseX - arcCenterX);
    const boundaryAngle = 90;

    let degrees = reverse
        ? valueWithStep(radiansToDegrees(radians) + boundaryAngle, step)
        : valueWithStep(radiansToDegrees(radians) - boundaryAngle, step);

    if (reverse) {
        degrees =
            degrees >= 260 ? -boundaryAngle : Math.min(degrees, boundaryAngle);
    } else {
        degrees =
            degrees >= -270 && degrees <= -180
                ? boundaryAngle
                : Math.max(degrees, -boundaryAngle);
    }

    return degrees;
}

/**
 * Compute relative range value in relation to degrees angle.
 * @param {number} degrees
 * @param {number} minValue
 * @param {number} maxValue
 * @param {number} step
 * @returns {number}
 */
function computeArcRelativeValue(degrees, minValue, maxValue, step = 1) {
    return valueWithStep(
        Math.abs(computeRelativeScale(degrees, -90, 90, minValue, maxValue)),
        step
    );
}

/**
 * Compute relative degrees value in relation to range input value.
 * @param {number} degrees
 * @param {number} minValue
 * @param {number} maxValue
 * @param {number} step
 * @returns {number}
 */
function computeDegreeRelativeValue(rangeValue, minValue, maxValue, step = 1) {
    return -valueWithStep(
        computeRelativeScale(rangeValue, -90, 90, minValue, maxValue),
        step
    );
}

/**
 * Set both range field and input field values;
 * @param {HTMLInputElement} inputField
 * @param {HTMLInputElement} rangeField
 * @param {number} value
 */
function setArcRangeInputValue(inputField, rangeField, value) {
    if (isNaN(value)) {
        return;
    }

    inputField.value = value;
    rangeField.value = value;
    inputField.setAttribute("value", value);
    rangeField.setAttribute("value", value);
}

/**
 * Find closest value for a given step.
 * @param {number} value
 * @param {number} step
 * @returns {number}
 */
function valueWithStep(value, step = 1) {
    return Math.ceil(value / step) * step;
}
