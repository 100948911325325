import { isBaseRowEmpty } from "./form_validator/extensions/collection/common/visit_repeater";
import { FormValidator } from "./form_validator/form_validator";

const MS_PER_YEAR = 365.2425 * 24 * 60 * 60 * 1000;

/**
 * Check if DOB inputs are present on the page.
 * @param {HTMLInputElement} yyyyInput
 * @param {HTMLInputElement} mmInput
 * @param {HTMLInputElement} ddInput
 * @returns {boolean}
 */
export function hasDobInputs(yyyyInput, mmInput, ddInput) {
    return yyyyInput && mmInput && ddInput;
}

/**
 * Calculate age from a referencial date.
 * @param {Date} dob
 * @param {Date} refDate
 * @returns {number}
 */
export function calculateAgeInDecimal(dob, refDate = new Date()) {
    const diffMs = refDate - dob;
    return (diffMs / MS_PER_YEAR).toFixed(1);
}

/**
 * Create age input to avoid sending date of birth
 * information to the backend.
 * @param {Date} dob
 * @param {HTMLElement} context
 */
export function createAgeInput(dob, context) {
    const age = calculateAgeInDecimal(dob);
    createHiddenInput("age", age, context);
}

/**
 * Create age input to avoid sending date of birth information to the backend,
 * calculating age based on the visit date.
 * @param {Date} dob
 * @param {Date} visitDate
 * @param {HTMLElement} context
 */
export function createAgeInputAtVisit(dob, visitDate, context) {
    const age = calculateAgeInDecimal(dob, visitDate); // Calculate age based on the visit date
    createHiddenInput("age", age, context);
}

/**
 * Create hidden input element.
 * @param {string} name
 * @param {*} value
 * @param {HTMLElement} context
 */
export function createHiddenInput(name, value, context) {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = name;
    input.value = value;
    context.appendChild(input);
}

/**
 * Create age list input to avoid sending date of birth information
 * to the backend.
 * @param {Date} dob
 * @param {HTMLElement} context
 * @param {string} name
 */
export function createAgeListInput(dob, context, name, repeaterId) {
    const dates = document.getElementsByName(name);
    const ages = [];
    const [isEmpty] = isBaseRowEmpty(repeaterId);

    dates.forEach((dateElement) => {
        if (isEmpty && dateElement.id === name) {
            return;
        }

        if (dateElement.value) {
            const date = new Date(dateElement.value);
            const age = calculateAgeInDecimal(dob, date);
            ages.push(age);
        } else {
            ages.push("");
        }
    });

    const ageName = name.replace("date", "ages");
    createHiddenInput(ageName, JSON.stringify(ages), context);
}

/**
 * Retrives list of DOB inputs ordered as yyyy,mm,dd.
 * @returns {HTMLInputElement[]}
 */
export function getDobInputs() {
    return [
        document.getElementById("dob-yyyy"),
        document.getElementById("dob-mm"),
        document.getElementById("dob-dd"),
    ];
}

/**
 * Build DOB date with format yyyy/mm/dd.
 * @param {HTMLInputElement} yyyyInput
 * @param {HTMLInputElement} mmInput
 * @param {HTMLInputElement} ddInput
 * @returns {Date}
 */
function buildDobDate(yyyyInput, mmInput, ddInput) {
    return new Date(`${yyyyInput.value}/${mmInput.value}/${ddInput.value}`);
}

/**
 * Disable DOB values to prevent them from being
 * sent to the backend.
 * @param {HTMLInputElement} yyyyInput
 * @param {HTMLInputElement} mmInput
 * @param {HTMLInputElement} ddInput
 */
function disableDobInputs(yyyyInput, mmInput, ddInput) {
    yyyyInput.disabled = true;
    mmInput.disabled = true;
    ddInput.disabled = true;
}

/**
 * Enable DOB values;
 * @param {HTMLInputElement} yyyyInput
 * @param {HTMLInputElement} mmInput
 * @param {HTMLInputElement} ddInput
 */
function enableDobInputs(yyyyInput, mmInput, ddInput) {
    yyyyInput.disabled = false;
    mmInput.disabled = false;
    ddInput.disabled = false;
}

/**
 * Calculate age from dob input on meye forms.
 * @param {HTMLFormElement} form
 */
function onSubmitMeyeWithDob(form) {
    const [yyyyInput, mmInput, ddInput] = getDobInputs();
    const dob = buildDobDate(yyyyInput, mmInput, ddInput);

    const visitDateInput = document.getElementById("visit_date");
    const visitDate = new Date(visitDateInput.value);

    createAgeInputAtVisit(dob, visitDate, form);
    disableDobInputs(yyyyInput, mmInput, ddInput);
}

/**
 * Calculate age from dob input on meye score forms.
 * @param {HTMLFormElement} form
 */
export function onSubmitScoreWithDob(form) {
    const [yyyyInput, mmInput, ddInput] = getDobInputs();

    if (hasDobInputs(yyyyInput, mmInput, ddInput)) {
        const dob = buildDobDate(yyyyInput, mmInput, ddInput);

        createAgeListInput(dob, form, "visit-date", "visit-input-repeater");
        createAgeListInput(dob, form, "start-date", "treatment-input-repeater");
        createAgeListInput(dob, form, "stop-date", "treatment-input-repeater");
        disableDobInputs(yyyyInput, mmInput, ddInput);
    }
}

/**
 * Adds listener to meye forms to handle age calculations.
 * @param {HTMLFormElement} form
 * @param {boolean} validate
 */
function handleMeyeDob(form, validate = false) {
    if (!form) {
        return;
    }

    form.addEventListener("submit", (event) => {
        event.preventDefault();

        const shouldSubmit =
            !validate || FormValidator.getInstance(form.id).validateForm();

        if (shouldSubmit) {
            onSubmitMeyeWithDob(form);
            form.submit();
        }
    });
}

/**
 * Create hidden inputs with age values calculated from the visit dates,
 * start dates and stop dates.
 */
export function treatDobInputs() {
    const guideForm = document.getElementById("submit-guide-form");
    const alestForm = document.getElementById("submit-alest-form");

    handleMeyeDob(guideForm, true);
    handleMeyeDob(alestForm);
}

/**
 * Enable DOB inputs on unload page.
 */
export function onUnloadPageWithDob() {
    window.addEventListener("beforeunload", () => {
        const [yyyyInput, mmInput, ddInput] = getDobInputs();
        if (hasDobInputs(yyyyInput, mmInput, ddInput)) {
            enableDobInputs(yyyyInput, mmInput, ddInput);
        }
    });
}
