import { cut, upperCaseFirstLetter } from "../../../utils";
import { FormValidatorExtension } from "../extension";

export class AxialExtension extends FormValidatorExtension {
    measureClass = "measure-axial-container";
    estimateClass = "estimate-axial-container";

    constructor() {
        super();
        this.measureContainer = document.getElementById(this.measureClass);
        this.estimateContainer = document.getElementById(this.estimateClass);
    }

    getLinkedKey(key) {
        return key.startsWith("link")
            ? key
            : `link${upperCaseFirstLetter(key)}`;
    }

    getLinkedField(key) {
        return this.formValidator.getField(this.getLinkedKey(key));
    }

    replaceKey(key, searchValue, replaceValue) {
        return key.includes(searchValue)
            ? key.replace(searchValue, replaceValue)
            : key.replace(replaceValue, searchValue);
    }

    replaceEstimatedField(key, searchKey, replaceKey) {
        const replacedKey = this.replaceKey(key, searchKey, replaceKey);
        return this.getEstimatedInput(replacedKey);
    }

    filterInputs(filterFunction) {
        return [...this.formValidator.fields].reduce((prev, curr) => {
            const notRepeated = !prev.find(
                (field) =>
                    field.validatorKey ===
                    this.getLinkedKey(curr[1].validatorKey)
            );

            if (notRepeated && filterFunction(curr[1])) {
                prev.push(this.getLinkedField(curr[1].validatorKey));
            }

            return prev;
        }, []);
    }

    getMeasuredInputs() {
        return this.filterInputs(this.hasMeasuredClass);
    }

    getEstimatedInputs() {
        return this.filterInputs(this.hasEstimatedClass);
    }

    getEmptyEstimatedInputs() {
        return this.getEstimatedInputs().filter(
            (input) => !input.effectiveValue
        );
    }

    getPickKr() {
        return this.formValidator.getField("pickKR").effectiveValue;
    }

    getOppositeKR() {
        return this.getPickKr() === "k" ? "r" : "k";
    }

    isInputInvalid(currentField, fieldToCheck) {
        return (
            fieldToCheck.validatorKey !== currentField.validatorKey &&
            !fieldToCheck.effectiveValue
        );
    }

    handleInput(currentField, fieldToCheck) {
        if (this.isInputInvalid(currentField, fieldToCheck)) {
            this.invalidateInput(fieldToCheck);
        } else if (
            !currentField.effectiveValue &&
            fieldToCheck.effectiveValue
        ) {
            this.invalidateInput(currentField);
        }
    }

    handleKrInputs(field) {
        const matchKey = this.replaceKey(field.validatorKey, "1", "2");

        const matchInput = this.getEstimatedInputs().find(
            (input) => input.validatorKey === matchKey
        );

        if (matchInput) {
            this.handleInput(field, matchInput);
        }

        this.getEstimatedInputs().forEach((input) => {
            if (input.hasErrors && field.validatorKey !== input.validatorKey) {
                this.formValidator.runValidation(input);
            }
        });
    }

    hasMeasuredClass(field) {
        return field.element.classList.contains("measured-input");
    }

    hasEstimatedClass(field) {
        return field.element.classList.contains("estimated-input");
    }

    isAxialLengthInput(field) {
        return this.hasMeasuredClass(field) || this.hasEstimatedClass(field);
    }

    invalidateInput(field) {
        const [selector, className] = this.formValidator.getErrorSelector(
            field.element
        );

        const errorMessage =
            field?.errors[0] ??
            this.formValidator.getCustomError(field.validatorKey).required;

        field.updateErrors([errorMessage]);

        selector.classList.add(className);
    }

    cleanInputErrors(field) {
        const [selector, className] = this.formValidator.getErrorSelector(
            field.element
        );

        field.updateErrors([]);
        selector.classList.remove(className);
    }

    checkField(field) {
        if (!field.effectiveValue) {
            this.invalidateInput(field);
        } else {
            this.cleanInputErrors(field);
        }
    }

    isMeasuredFilled() {
        return this.getMeasuredInputs().some((input) => input.effectiveValue);
    }

    isEstimatedFilled() {
        return this.getEstimatedInputs().some((input) => input.effectiveValue);
    }

    enableEstimateContainer() {
        this.measureContainer.classList.add("disabled-measured");
        this.estimateContainer.classList.remove("disabled-estimated");
    }

    enableMeasureContainer() {
        this.estimateContainer.classList.add("disabled-estimated");
        this.measureContainer.classList.remove("disabled-measured");
    }

    enableBothContainers() {
        this.estimateContainer.classList.remove("disabled-estimated");
        this.measureContainer.classList.remove("disabled-measured");
    }

    syncEmptyKRValues(field) {
        if (!this.hasEstimatedClass(field)) {
            return;
        }

        const oppositeKR = this.getOppositeKR();
        const eye = field.validatorKey.slice(-3);

        const opposite = this.getEstimatedInputs().find(
            (input) =>
                input.validatorKey === `link${oppositeKR.toUpperCase()}${eye}`
        );

        if (!field.effectiveValue && opposite.effectiveValue) {
            opposite.setValue = "";
        } else {
            this.cleanInputErrors(opposite);
        }
    }

    handleIsMeasuredFilled(field) {
        this.enableMeasureContainer();

        this.getMeasuredInputs().forEach((input) => {
            this.handleInput(field, input);
        });

        this.getEstimatedInputs().forEach((input) => {
            this.cleanInputErrors(input);
        });
    }

    handleIsEstimatedFilled(field) {
        this.enableEstimateContainer();
        this.handleKrInputs(field);

        if (this.formValidator.submitter.disabled) {
            const emptyFields = this.getEmptyEstimatedInputs();

            emptyFields.forEach((field) => {
                this.invalidateInput(field);
            });
        }

        this.getMeasuredInputs().forEach((input) => {
            this.cleanInputErrors(input);
        });
    }

    handleNoneIsFilled() {
        this.enableBothContainers();

        [...this.getMeasuredInputs(), ...this.getEstimatedInputs()].forEach(
            (input) => this.cleanInputErrors(input)
        );
    }

    getEstimatedInput(key) {
        return this.getEstimatedInputs().find(
            (estField) => estField.validatorKey === key
        );
    }

    handleKrToggle() {
        const oppositeKR = this.getOppositeKR();

        const oppositeFields = this.getEstimatedInputs().filter((oppField) => {
            const key = cut(oppField.validatorKey, "link").toLowerCase();
            return key.includes(oppositeKR) && oppField.effectiveValue;
        });

        const currentFields = oppositeFields.map((oppField) =>
            this.replaceEstimatedField(oppField.validatorKey, "K", "R")
        );
        const dependentFields = currentFields.map((currField) =>
            this.replaceEstimatedField(currField.validatorKey, "1", "2")
        );

        [...currentFields, ...dependentFields].forEach((field) => {
            this.checkField(field);
        });
    }

    handleAxialLengthInput(field) {
        if (this.isMeasuredFilled()) {
            this.handleIsMeasuredFilled(field);
        } else if (this.isEstimatedFilled()) {
            this.handleIsEstimatedFilled(field);
        } else {
            this.handleNoneIsFilled();
        }
    }

    beforeValidate() {
        const kr = this.formValidator.getField("pickKR");
        kr.updateElement();
    }

    afterValidate(field) {
        if (field.validatorKey === "pickKR") {
            this.handleKrToggle();
        } else if (this.isAxialLengthInput(field)) {
            this.syncEmptyKRValues(field);
            this.handleAxialLengthInput(field);
        }
    }

    beforeSubmit() {
        if (this.isMeasuredFilled()) {
            this.getEstimatedInputs().forEach((input) => {
                input.setValue = "";
                this.cleanInputErrors(input);
            });
        } else if (!this.isMeasuredFilled() && this.isEstimatedFilled()) {
            const fields = this.getEmptyEstimatedInputs();

            fields.forEach((field) => {
                this.handleIsEstimatedFilled(field);
            });
        }
    }
}
